import { Routes } from '@angular/router';
import { authGuard } from '../guards/auth.guard';

const authRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('../../auth/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('../../auth/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },
];

const MainApp: Routes = [
  {
    path: 'main-app',
    loadComponent: () =>
      import('../../pages/main-app/main-app.component').then(
        (c) => c.MainAppComponent
      ),
    children: [
      {
        path: 'data-management',
        loadComponent: () =>
          import(
            '../../components/data-management/data-management.component'
          ).then((c) => c.DataManagementComponent),
      },
      {
        path: 'org-management',
        loadComponent: () =>
          import(
            '../../components/org-management/org-management.component'
          ).then((c) => c.OrgManagementComponent),
      },
      {
        path: 'user-management',
        loadComponent: () =>
          import(
            '../../components/user-management/user-management.component'
          ).then((c) => c.UserManagementComponent),
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('../../components/dashboard/dashboard.component').then(
            (c) => c.DashboardComponent
          ),
      },

      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
    ],
    canActivate: [authGuard],
  },
];

// Org Management Routes
const orgManagementRoutes: Routes = [
  {
    path: 'org-management',
    loadComponent: () =>
      import('../../pages/org-management/org-management.component').then(
        (c) => c.OrgManagementComponent
      ),
    children: [
      {
        path: 'attorney-orgs',
        loadComponent: () =>
          import(
            '../../components/org-management/attorney-orgs/attorney-orgs.component'
          ).then((c) => c.AttorneysComponent),
      },
      {
        path: 'attorney-orgs/add/:id',
        loadComponent: () =>
          import(
            '../../components/org-management/attorney-orgs/add-edit-attorney/add-edit-attorney.component'
          ).then((c) => c.AddEditAttorneyComponent),
      },
      {
        path: 'provider-orgs',
        loadComponent: () =>
          import(
            '../../components/org-management/provider-orgs/providers.component'
          ).then((c) => c.ProvidersComponent),
      },
      {
        path: 'provider-orgs/add/:id',
        loadComponent: () =>
          import(
            '../../components/org-management/provider-orgs/add-edit-provider/add-edit-provider.component'
          ).then((c) => c.AddEditProviderComponent),
      },
      {
        path: `diagnostic-orgs`,
        loadComponent: () =>
          import(
            '../../components/org-management/diagnostic-orgs/diagnostic-orgs.component'
          ).then((c) => c.DiagnosticOrgsComponent),
      },
      {
        path: `diagnostic-orgs/add/:id`,
        loadComponent: () =>
          import(
            '../../components/org-management/diagnostic-orgs/add-edit-diagonstic-orgs/add-edit-diagonstic-orgs.component'
          ).then((c) => c.AddEditDiagonsticOrgsComponent),
      },
      {
        path: `procedure-orgs`,
        loadComponent: () =>
          import(
            '../../components/org-management/procedure-orgs/procedure-orgs.component'
          ).then((c) => c.ProcedureOrgsComponent),
      },
      {
        path: `procedure-orgs/add/:id`,
        loadComponent: () =>
          import(
            '../../components/org-management/procedure-orgs/add-edit-procedure-orgs/add-edit-procedure-orgs.component'
          ).then((c) => c.AddEditProcedureOrgsComponent),
      },
      {
        path: `historian-orgs`,
        loadComponent: () =>
          import(
            '../../components/org-management/historian-orgs/historian-orgs.component'
          ).then((c) => c.HistorianOrgsComponent),
      },
      {
        path: `historian-orgs/add/:id`,
        loadComponent: () =>
          import(
            '../../components/org-management/historian-orgs/add-edit-historian-org/add-edit-historian-org.component'
          ).then((c) => c.AddEditHistorianOrgComponent),
      },
      {
        path: `biller-orgs`,
        loadComponent: () =>
          import(
            '../../components/org-management/biller-orgs/biller-orgs.component'
          ).then((c) => c.BillerOrgsComponent),
      },
      {
        path: `biller-orgs/add/:id`,
        loadComponent: () =>
          import(
            '../../components/org-management/biller-orgs/add-edit-biller-orgs/add-edit-biller-orgs.component'
          ).then((c) => c.AddEditBillerOrgsComponent),
      },
    ],
  },
];

const dataManagementRoutes: Routes = [
  {
    path: 'data-management',
    loadComponent: () =>
      import('../../pages/data-management/data-management.component').then(
        (c) => c.DataManagementComponent
      ),
    children: [
      {
        path: `helpdesk-cms`,
        loadComponent: () =>
          import(
            '../../components/data-management/helpdesk-cms/helpdesk-cms.component'
          ).then((c) => c.HelpdeskCmsComponent),
      },
      {
        path: `helpdesk-cms/add/:app`,
        loadComponent: () =>
          import(
            '../../components/data-management//helpdesk-cms/add-edit-video-article/add-edit-video-article.component'
          ).then((c) => c.AddEditVideoArticleComponent),
      },
    ],
  },
];

const userManagementRoutes: Routes = [
  {
    path: 'user-management',
    loadComponent: () =>
      import('../../pages/user-management/user-management.component').then(
        (c) => c.UserManagementComponent
      ),
    children: [
      {
        path: `org-users/:data/:path/:type`,
        loadComponent: () =>
          import(
            '../../components/user-management/org-users/org-users.component'
          ).then((c) => c.OrgUsersComponent),
      },
      {
        path: 'add-edit-org-user/new/:id/:path/:orgCode',
        loadComponent: () =>
          import(
            '../../components/user-management/add-edit-org-user/add-edit-org-user.component'
          ).then((c) => c.AddEditOrgUserComponent),
      },
      {
        path: 'add-edit-org-user/edit/:id/:path',
        loadComponent: () =>
          import(
            '../../components/user-management/add-edit-org-user/add-edit-org-user.component'
          ).then((c) => c.AddEditOrgUserComponent),
      },
    ],
  },
];

const providerManagementRoutes: Routes = [{
  path: 'provider-management',
  loadComponent: () =>
    import(
      '../../pages/provider-management/provider-management.component'
    ).then((c) => c.ProviderManagementComponent),
  children: [
    {
      path: 'manage-org',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-org/manage-org.component'
        ).then((c) => c.ManageOrgComponent),
    },
    {
      path: 'manage-modules',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-modules/manage-modules.component'
        ).then((c) => c.ManageModulesComponent),
    },
    {
      path: 'manage-facilities',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-facility/manage-facility.component'
        ).then((c) => c.ManageFacilityComponent),
    },
    {
      path: 'manage-providers',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-providers/manage-providers.component'
        ).then((c) => c.ManageProvidersComponent),
    },
    {
      path: 'manage-billers',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-billers/manage-billers.component'
        ).then((c) => c.ManageBillersComponent),
    },
    {
      path: 'manage-historians',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-historians/manage-historians.component'
        ).then((c) => c.ManageHistoriansComponent),
    },
    {
      path: 'manage-record-reviewers',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-record-reviewers/manage-record-reviewers.component'
        ).then((c) => c.ManageRecordReviewersComponent),
    },
    {
      path: 'manage-case-vue-users',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-case-vue-users/manage-case-vue-users.component'
        ).then((c) => c.ManageCaseVueUsersComponent),
    },
    {
      path: 'manage-appointments',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-apt-types/manage-apt-types.component'
        ).then((c) => c.ManageAptTypesComponent),
    },
    {
      path: 'manage-case-types',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-case-types/manage-case-types.component'
        ).then((c) => c.ManageCaseTypesComponent),
    },
    {
      path: 'manage-notes',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-notes-types/manage-notes-types.component'
        ).then((c) => c.ManageNotesTypesComponent),
    },
    {
      path: 'manage-order-types',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-order-types/manage-order-types.component'
        ).then((c) => c.ManageOrderTypesComponent),
    },
    {
      path: 'manage-forms',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-forms/manage-forms.component'
        ).then((c) => c.ManageFormsComponent),
    },
    {
      path: 'manage-reports',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-reports/manage-reports.component'
        ).then((c) => c.ManageReportsComponent),
    },
    {
      path: 'manage-pos',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-pos/manage-pos.component'
        ).then((c) => c.ManagePosComponent),
    },
    {
      path: 'manage-documents',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-documents/manage-documents.component'
        ).then((c) => c.ManageDocumentsComponent),
    },
    {
      path: 'manage-lookup',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-lookup/manage-lookup.component'
        ).then((c) => c.ManageLookupComponent),
    },
    {
      path: 'manage-notifictaions',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-notification/manage-notification.component'
        ).then((c) => c.ManageNotificationComponent),
    },
    {
      path: 'manage-integrations',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-integration/manage-integration.component'
        ).then((c) => c.ManageIntegrationComponent),
    },
    {
      path: 'manage-conf-workflows/:id',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-conf-workflow/manage-conf-workflow.component'
        ).then((c) => c.ManageConfWorkflowComponent),
    },
    {
      path: 'list-workflow',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-conf-workflow/list-workflow/list-workflow.component'
        ).then((c) => c.ListWorkflowComponent),
    },
    {
      path: 'manage-conf-workflows/phases/:id',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-conf-workflow/phases/phases.component'
        ).then((c) => c.PhasesComponent),
    },
    {
      path: 'manage-conf-workflows/phases/stages/:id',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-conf-workflow/stages/stages.component'
        ).then((c) => c.StagesComponent),
    },
    {
      path: 'add-provider-user/new/:id/:path/:orgCode',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-providers/add-new-provider/add-new-provider.component'
        ).then((c) => c.AddNewProviderComponent),
    },
    {
      path: 'provider-user',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-providers/list-provider-users/list-provider-users.component'
        ).then((c) => c.ListProviderUsersComponent),
    },
    {
      path: 'edit-provider-user/edit',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-providers/edit-provider-user/edit-provider-user.component'
        ).then((c) => c.EditProviderUserComponent),
    },
    {
      path: 'edit-provider-user/new/:id',
      loadComponent: () =>
        import(
          '../../components/provider-management/manage-providers/edit-provider-user/edit-provider-user.component'
        ).then((c) => c.EditProviderUserComponent),
    }
  ]
}];

// Default Routes
const defaultRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '**', redirectTo: 'login' },
];

export const routes: Routes = [
  ...authRoutes,
  ...MainApp,
  ...orgManagementRoutes,
  ...dataManagementRoutes,
  ...userManagementRoutes,
  ...providerManagementRoutes,
  ...defaultRoutes,
];
