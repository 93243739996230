import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { RibbonComponent } from '@mlc/shared//ribbon/ribbon.component';
import { ToastMessageComponent } from '@mlc/shared/toast-message/toast-message-component.component';
import { FooterComponent } from './shared/footer/footer.component'
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RibbonComponent, ToastMessageComponent,FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  environment = environment;
  private matIconReg = inject(MatIconRegistry);
  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }
}
